import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import { useContract, claim } from "./domains/contract";
import backgroundImage from "./images/bg.jpg"

function App() {
  const { isActive, connector, account, provider } = useWeb3React()
  const { loading, isWhitelisted, claimStarted, didClaim } = useContract(account, provider)
  const [sending, setSending] = useState(false)

  useEffect(() => {
    if (connector && connector.connectEagerly) {
      connector.connectEagerly()
    }
  }, [connector])

  const onConnectClick = async () => {
    if (!isActive) {
      return await connector.activate(1)
    }
  }

  const onClaimClick = async () => {
    setSending(true)
    const tx = await claim(account, provider)
    await tx.wait()
    setSending(false)
  }

  return (
    <div className="flex items-center min-h-screen bg-cover p-6 md:p-10 lg:p-16" style={{
      backgroundImage: `url('${backgroundImage}')`,
    }}>
      <div className="card relative w-full lg:w-auto lg:min-w-1/2 lg:pr-36 flex items-center">
        <h1 className="font-serif text-4xl lg:text-5xl xl:text-7xl text-white">Offering Salvation<br/>for the Fallen</h1>
        <div className="absolute bottom-0 right-0 translate-y-1/2 -translate-x-[2rem]">
          {!isActive && <button className="btn" onClick={onConnectClick}>Connect your wallet</button>}

          {isActive && (
            <>
              {loading && <span className="btn">Loading...</span>}
              {!loading && (
                <>
                  {sending && <span className="btn">Claiming...</span>}
                  {!sending && (
                    <>
                      {!isWhitelisted && <span className="btn">Not whitelisted</span>}
                      {isWhitelisted && (
                        <>
                          {!didClaim && claimStarted && (
                            <button className="btn" onClick={onClaimClick}>Claim with {account?.substring(0, 4) + ".." + account?.substring(account.length - 5, account.length - 1)}</button>
                          )}
                          {!didClaim && !claimStarted && (
                            <span className="btn">Be patient...</span>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
