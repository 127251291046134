import { ethers } from "ethers"
import { CONTRACT_ADDRESS } from "./../constants"
import { useState } from "react"
import useSWR from "swr"
import Redimere from "./../abis/Redimere.json"
import merkle from "../merkleTree"

export function useContract(account: any, provider: any) {
    const [loading, setLoading] = useState(true)
    const [claimStarted, setClaimStarted] = useState(false)
    const [isWhitelisted, setIsWhitelisted] = useState(false)
    const [didClaim, setDidClaim] = useState(false)

    useSWR([account, provider], () => {
        async function main() {
            if (!account || !provider) {
                return
            }

            const contract = getContract(provider)

            const data = await Promise.all([
                contract.claimStarted(),
                contract.isWhitelisted(account, merkle.getHexProof(ethers.utils.keccak256(account))),
                contract.didClaim(account),
            ])

            setClaimStarted(data[0])
            setIsWhitelisted(data[1])
            setDidClaim(data[2])
            setLoading(false)
        }

        main()
    })

    return { loading, isWhitelisted, claimStarted, didClaim }
}

export async function claim(account: any, provider: any): Promise<any> {
    const contract = getContract(provider)
    return await contract.claim(merkle.getHexProof(ethers.utils.keccak256(account)))
}

function getContract(provider: any) {
    return new ethers.Contract(CONTRACT_ADDRESS, Redimere.abi, provider.getSigner())
}