import { ethers } from "ethers"
import MerkleTree from "merkletreejs"
import addresses from "./whitelists.json"

const leafs = addresses
    .map(address => ethers.utils.getAddress(address))
    .map(address => ethers.utils.keccak256(address))

const merkle = new MerkleTree(leafs, ethers.utils.keccak256, { sortPairs: true })

export default merkle